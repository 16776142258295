import { EsriProvider, GoogleProvider } from 'leaflet-geosearch';
import {
  GOOGLE_PLACES_API_KEY,
  ADDRESS_AUTOCOMPLETE_PROVIDER,
} from '~/constants';

export const provider =
  ADDRESS_AUTOCOMPLETE_PROVIDER === 'esri'
    ? new EsriProvider()
    : new GoogleProvider({
        apiKey: GOOGLE_PLACES_API_KEY,
      });

export interface IGeocodingResult {
  x: number; // lon,
  y: number; // lat,
  label: string; // formatted address
  bounds:
    | [
        [number, number], // s, w - lat, lon
        [number, number], // n, e - lat, lon
      ]
    | null;
  raw: {
    name: string;
  };
}

export async function geocode(address: string): Promise<IGeocodingResult[]> {
  const results = await provider.search({ query: address });
  return results;
}
